import request from './request'

const api = {
	// 列表
	lists: '/Banner/index',
	// 新增
	add: '/Banner/create',
	// 删除
	dele: '/Banner/delete',
	// 详情
	read: '/Banner/read',
	// 编辑
	edit: '/Banner/update',
}

export {
	request,
	api
}
