<template>
	<div>
		<div class="dialog">
			<el-form ref="ruleForm" label-width="80px">
				<el-form-item label="封面图">
					<span class="xx">*</span>
					<div class="dialog-up">
						<!-- 预览图 -->
						<img v-if="ruleForm.cover" class="dialog-img" :src="ruleForm.cover" />
						<p v-if="ruleForm.cover" class="dele" @click="dele">删除</p>
						<el-upload :headers="Headers" :action="ActionUrl" :on-success="handleAvatarSuccess">
							<div class="upload">
								<i class="icon iconfont icon-shangchuan"></i>
							</div>
						</el-upload>
					</div>
				</el-form-item>
				<el-form-item label="排序">
					<span class="xx"></span>
					<el-input clearable v-model="ruleForm.sort" placeholder="请输入排序"></el-input>
				</el-form-item>

				<el-form-item label="名称">
					<span class="xx">*</span>
					<el-input clearable v-model="ruleForm.name" placeholder="请输入名称"></el-input>
				</el-form-item>
				<el-form-item label="状态">
					<span class="xx"></span>
					<el-select v-model="ruleForm.status" placeholder="请选择状态">
						<el-option label="启用" :value="1"></el-option>
						<el-option label="禁用" :value="0"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/banner";
	export default {
		props: ['title', 'mid', 'identity'],
		data() {
			return {
				Headers: {
					authorization: '',
					appid:'',
					nonce:'',
					timestamp:'',
					sign:''
					
				},
				ActionUrl: '',
				ruleForm: {
					name: '',
					cover: '',
					sort: '',
					status: 1
				}

			}
		},
		mounted() {
			// 上转图片url
			this.Headers.authorization = sessionStorage.getItem('token')
			let param = JSON.parse(sessionStorage.getItem('param'))
			this.Headers.appid = param.appid
			this.Headers.nonce = param.nonce
			this.Headers.timestamp = param.timestamp
			this.Headers.sign = param.sign
			
			this.ActionUrl = sessionStorage.getItem('baseURL') + '/System/FormUpload'
			if (this.title == '编辑') {
				this.getread()
			}
		},
		methods: {
			// 获取详情
			getread() {
				this.$http.post(api.read, {
						id: this.mid
					})
					.then((res) => {
						if (res.code == 1) {
							res.data.roles = Number(res.data.roles)
							this.ruleForm = res.data
						}
					})
			},

			// 删除图片
			dele() {
				this.ruleForm.cover = ''
			},
			// 上转图片
			handleAvatarSuccess(res, file) {
				this.ruleForm.cover = res.data
			},
			// 清空数据
			Reset() {
				for (let key in this.ruleForm) {
					this.ruleForm[key] = ''
				}
			},
			//新增/编辑
			SubmitForm() {
				if (this.ruleForm.cover === '') {
					ElMessage({
						message: '请上传封面图',
						type: 'warning',
					})
				} else if (this.ruleForm.name === '') {
					ElMessage({
						message: '请输入名称',
						type: 'warning',
					})
				} else {
					if (this.title == '新增') {
						this.add()
					} else {
						this.edit()
					}

				}

			},
			// 编辑
			edit() {
				this.$http.post(api.edit, this.ruleForm)
					.then((res) => {
						if (res.code == 1) {
							ElMessage({
								type: 'success',
								message: '编辑成功',
							})
							this.$EventBus.emit('RefreshUser')
							this.$emit('close')
							// 刷新列表
							this.$emit('SubmitForm')
						}
					})
			},
			// 新增
			add() {
				this.$http.post(api.add, this.ruleForm)
					.then((res) => {
						if (res.code == 1) {
							ElMessage({
								type: 'success',
								message: '新增成功',
							})
							this.$emit('close')
							// 刷新列表
							this.$emit('SubmitForm')
						}
					})
			}
		}
	}
</script>

<style scoped>
</style>
